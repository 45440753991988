<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'App',
  created() {
    document.title = 'Continue';
    window.location.href = 'https://app.expertchat.me';
  },
};
</script>
