<template>
  <div class="success">
    <div class="success-container">
      <img class="success-image" src="../assets/400p.png" alt="">
      <h1 class="success-title">
        Click to continue your experience
      </h1>
      <a href="https://app.expertchat.me" class="btn btn-primary success-button">
        Continue
      </a>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Home',
  components: {
  },
};
</script>

<style lang="scss" scoped>
.success {
  font-family: 'Roboto', sans-serif;

  &-container {
    position: absolute;
    padding: 0 15px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &-image {
    max-width: 450px;
    width: 100%;
  }

  &-title {
    font-size: 2rem;
    text-align: center;
  }

  &-button {
    font-size: 1rem;
    color: #fff;
    background-color: #2787f5;
    display: inline-block;
    font-weight: 400;
    line-height: 1.6;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    border: 2px solid #0000;
    padding: 0.59375rem 1.125rem;
    border-radius: 0.6rem;
    transition: color .15s ease-in-out,
      background-color .15s ease-in-out,
      border-color .15s ease-in-out,
      box-shadow .15s ease-in-out;

    &:hover {
      background-color: #0f79f4;
    }
  }
}

</style>
